.Project-Link {
    cursor: pointer;
    text-align: left;
    opacity: 1;
    color: #0091ff;
    position: relative;
    height: 20%;
    margin: 2px;
    /* padding-left: 20px; */
}


.Project-Title-Selected {
    opacity: 1;
    color: #0091ff;
    font-size: 2em;
    color: #00ffff !important;
    padding-left: 50px;
    padding-top: 3px;
}

.Project-Summary-Wrapper {
    display: none;
    height: 100%;
    position: relative;
}


/* .Project-Wrapper::-webkit-scrollbar {
    display: none;
} */

/* Hide scrollbar for Chrome, Safari and Opera */


.Project-Header {
    width: 100%;
    position: relative;
    z-index: 3;
    position: sticky;
    top: 0px;
    background-color: (2,2,2,0.5);
    /* background-color: */
    /* text-shadow: #000 3px 10px 10px; */
    /* backdrop-filter: blur(4px); */
     
}


.project_thumbnail {
    width: 100%;
    height: 130px;
    background-color: transparent;
    position: relative;
    background-size:cover;
    background-position:center;
    /* border-top: 2px solid #0077ff; */
    /* border-right: 2px solid #0077ff; */
    border-radius: 5px;
}


/* Rounded border */
hr.rounded {
    border: 1.5px solid #0077ff;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 20px;
  }

.Project-Menu {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    /* background-color: transparent ; */
    /* backdrop-filter: blur(4px); */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: linear-gradient(to top, rgba(2,2,2,0), 30%, rgba(2,2,2,1));
    /* background-color: black; */
}

.Project-Menu > div {
    text-align: center;
    top: 10px;
    position: relative;
    color: #0091ff;
    font-size: 2em;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;

}

.Selected {
    font-weight: bolder;
    color: #00ffff !important;
    
}

.Project-Directory-Wrapper {
    display: none;
    z-index: 2;
}
.Project-Directory-Content {
    width: 100%;
    height: 100%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
    z-index: 2;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: none;
}
.Project-Wrapper {
    /* display: none; */
    z-index: 2;
    /* overflow-x: overlay; */
}
.hover {
    opacity: 1;
    color: #00ffff !important;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.box_shadow {
    border: #00ffff solid;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.Project-Title {
    font-size: 2em;
    position: relative;
}

.Inactive-Project-Title {
    font-size: 2em;
    position: relative;
    opacity: .5;
}

.Project-Description {
     height: 5px;
     padding-left: 50px;
     font-size: 1em;
     opacity: .8;
     top: -10px;
     position: relative;
     opacity: .8;
    color: #0077ff;
    position: relative;
    font-style: italic;
 }

.Back-Button-Container:Hover {
    cursor: pointer;
}

.Back-Button-Container {
    z-index: 30;
}

.Back-Button-Click {
    width: 25px;
    height: 25px;
    position: relative;
    margin-left: 10px;
    margin-top: 16px;
}
.Back-Button {
    width: 25px;
    height: 25px;
    position: relative;
    margin-left: 10px;
    margin-top: 16px;
}

.Project-Description:Hover {

}