.Writeup {
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-size: 1.5em;
    /* padding: 40px;
    /* position: relative; */
}
    .Project-Summary {
        /* position: relative; */
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(4px);
    position: sticky;
    top: 5px;
    padding-right: 4%;
    padding-left: 4%;
    position: relative;
}
.link-1 {
    font-size: 1.5em;
    /*padding-left: 20px;*/
    /*color: #00ffff !important;*/

}
.caption {
    font-size: 1.5em;
    position: relative;
    top: -20px;
}
.Project-Date {
    font-size: 2em;
    color: #00ffff !important;
    padding-left: 50px;
}
.Project-Header-2 {
    background: linear-gradient(to top, rgba(2,2,2,0), 30%, rgba(2,2,2,1));
    z-index: 2;
}
.gallery-photo-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}
.gallery-photo {
    width: 100%;
    height: 100%;
    /* padding: 10px; */
    position: relative; 
    margin: 0px auto;
}

.projSummary {
    position: flex;
    flex-direction: column;
}

.link-1{
    font-size: 1.5em;
}

.Gallery {
    margin-top: 5px;
    font-size: 2em;
    padding-left: calc(50px - 4%);
    color: #00ffff !important;
    /* width: 100%;
    position: relative */
}