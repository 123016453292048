.About-Background {
    /*background-image: url("/me.jpg");*/
    width: 100%;
    height: 100% !important;
    background-size: cover;
    /* background-repeat: repeat; */
    opacity: .2;
    position: absolute;
    top: 0px;
    z-index: 2;

}

.About-Wrapper{
   position: relative;
    /* width: 100%; */
    height: 100% !important;
    z-index: 2;
}
.About-Content {
    font-size: 1.5em;
    font-weight: lighter;
    text-align: left;
    position: relative;
    padding-left: 6vw !important;
    padding-right: 6vw !important;
    padding-top: 6vw !important;
    padding-bottom: 6vw !important;
    /*margin: 6vw;*/
}

.bio {
    position: relative;
}



