@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family:'Quicksand', sans-serif !important;
  width: 100%;
  height: 100%;
  background-color: #000 !important;
  overflow: hidden;
  background-size: cover;
  line-height: 1.5 !important;


}

body .font {
  font-family:'Quicksand', sans-serif !important;
}

#root {
  width: 100%;
  height: 100%;


}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) and (max-width: 2000px) {
  body {
    font-size: 16px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (min-width: 2001px) {
  body {
    font-size: 18px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px){
  body {
    font-size: 12px;
  }
}
