.Desktop-Icon {
    width: 5em;
    height: 4.3em;
    background-size: contain;
    background-repeat: no-repeat;
    color: #fffbe8;
    font-size: 1em;
    position: absolute;
}

.Desktop-Icon:Hover {
    cursor: pointer;
}

.Desktop {
    width: 100%;
    height: 100%;
}
.Desktop-Background {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
}

#terminal {
    top: 41%;
    right: 52%;
    background-image: url("../../../public/icons/terminal.jpg");
    border-width: 1px;
    border-style: solid;
    border-color: magenta;
}
.computer-gif {
    top: calc(50% - 200px);
    right: calc(50% - 150px);
    background-image: url("../../../public/gifs/cp1.gif");
    width: 300px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}
.loading-bar-gif {
    top: calc(50% + 100px);
    right: calc(50% - 110px);
    background-image: url("../../../public/gifs/loading-bar.gif");
    width: 220px;
    height: 160px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}

#projects{
    /*right: 60%;*/
    /*top: 40%;*/
    top: 41%;
    right: 58%;
    background-image: url("../../../public/icons/folder.png");
}

#resume {
    right: 60%;
    top: 80%;
    background-image: url("../../../public/icons/file.png");
}

#about {
    right: 48%;
    top: 40%;
    background-image: url("../../../public/icons/file.png");
}

#blog {
    right: 80%;
    top: 80%;
    background-image: url("../../../public/icons/file.png");
}
#personal_statement {
    right: 23%;
    top: 20%;
    background-image: url("../../../public/icons/file.png");
}
.Desktop-Content {
    height: 100%;
    width: 100%;
    position: relative;
}
#record-player {
    top: 40%;
    right: 39%;
    background-image: url("../../../public/icons/RECORD.png");
}

.watermark {
    position: absolute;
    color: #ccc;
    bottom: 0px;
    left: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 11px;
}
.monogram {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 10%;
    min-width: 300px;
    height:8%;
    top: 20px;
    left: 15px;
    background-image: url("../../../public/icons/logo.png");
}
#contact-bar {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 10px;
    cursor: pointer;
}
.Icon {
    margin: 5px;
    opacity: 1;
    width: 2.5em;
    height: 2.5em;
}
.Git {
    margin-left: 8px;
    /*border: .8px solid white;*/
    padding: 0px;
    border-radius: 10px;
    right: 0px;
}

.Mail {
    margin-left: 12px;
}

.Li {
    padding: 1px;
}
.Icon:Hover {
    opacity: 1;
    cursor: pointer;
}